import React, { useState } from "react";
import Modal from "react-modal";
import "./ModalMarcador.css";
import { toast, Toaster } from "react-hot-toast";
import { salvarMarcador, enviarArquivo } from "./FirebaseDb"; // Importando as funções

Modal.setAppElement("#root");

const formElementStyles = {
  select: {
    padding: "15px",
    margin: "10px 0",
    borderRadius: "5px",
    border: "1px solid #602e77", // Borda laranja
    background: "#fff", // Fundo branco
    color: "#000", // Texto preto
    fontSize: "1rem",
  },
  input: {
    padding: "15px",
    margin: "10px 0",
    borderRadius: "5px",
    border: "1px solid #602e77", // Borda laranja
    background: "#fff", // Fundo branco
    color: "#000", // Texto preto
    fontSize: "1rem",
  },
  inputFile: {
    padding: "15px",
    margin: "10px 0",
    borderRadius: "5px",
    border: "1px solid #602e77", // Borda laranja
    background: "#fff", // Fundo branco
    color: "#000", // Texto preto
    fontSize: "1rem",
    cursor: "pointer",
  },
  button: {
    padding: "10px 20px",
    margin: "10px 0",
    borderRadius: "5px",
    border: "none",
    background: "#602e77", // Botão laranja
    color: "#fff", // Texto branco
    cursor: "pointer",
    transition: "background 0.3s",
  },
  buttonClose: {
    padding: "10px 20px",
    margin: "10px 0",
    borderRadius: "5px",
    border: "none",
    background: "#555", // Botão de fechar mais discreto
    color: "#fff", // Texto branco
    cursor: "pointer",
    transition: "background 0.3s",
  },
};

const modalStyles = {
  overlay: {
    backgroundColor: "rgba(0, 0, 0, 0.75)",
    zIndex: "2",
  },
  content: {
    width: "50%",
    height: "auto", // Altura automática para se ajustar ao conteúdo
    maxHeight: "80%", // Máximo de altura para evitar rolagem vertical
    margin: "auto",
    padding: "20px",
    display: "flex",
    flexDirection: "column",
    justifyContent: "space-between",
    background: "#fff", // Fundo branco
    color: "#000", // Texto preto para contraste
    border: "4px solid #602e77", // Borda laranja
    borderRadius: "10px", // Bordas arredondadas
    boxShadow: "0 4px 8px rgba(0, 0, 0, 0.5)", // Sombra para profundidade
    overflow: "hidden", // Esconde a rolagem vertical
    className: "modal-content"
  },
};


function ModalMarcador({ isOpen, onRequestClose, latitude, longitude }) {
  const [tipoArte, setTipoArte] = useState("grafite");
  const [comentario, setComentario] = useState("");
  const [arquivo, setArquivo] = useState(null);

  const handleFormSubmit = () => {
    if (arquivo) {
      enviarArquivo(arquivo).then((url) => {
        salvarMarcador(latitude, longitude, tipoArte, comentario, url);
      });
    } else {
      toast.error("Nenhum arquivo selecionado");
    }
    onRequestClose();
  };

  return (
<Modal
  isOpen={isOpen}
  onRequestClose={onRequestClose}
  contentLabel="My Modal"
  style={modalStyles}
>
  <h2>Divulgue a arte!</h2>
  <p>Tipo de arte:</p>
  <select style={formElementStyles.select} onChange={(e) => setTipoArte(e.target.value)}>
    <option value="grafite">Grafite</option>
    <option value="estencil">Estêncil</option>
    <option value="danca">Dança</option>
    <option value="canto">Canto</option>
  </select>
  <p>Digite um comentário:</p>
  <input
    type="text"
    placeholder="Digite algo aqui..."
    style={formElementStyles.input}
    onChange={(e) => setComentario(e.target.value)}
  />
  <p>Envie uma foto ou vídeo da obra:</p>
  <input
    type="file"
    style={formElementStyles.inputFile} // Aplica os estilos atualizados
    onChange={(e) => setArquivo(e.target.files[0])}
  />
  <button style={{ ...formElementStyles.button, ...formElementStyles.buttonClose }} onClick={onRequestClose}>Fechar</button>
  <button style={formElementStyles.button} onClick={handleFormSubmit}>Criar marcador!</button>
  <Toaster />
</Modal>
  );
}

export default ModalMarcador;
